import React, { useEffect, useState } from 'react';
import { Link, PageProps } from 'gatsby';
import { navigate } from 'gatsby';

import useDateFormat from 'src/hooks/useDateFormat';

import { Translate } from 'src/components/Translate';
import { useAuthContext } from 'src/components/AuthProvider';
import useCancelMembership from 'src/api/useCancelMembership';
import useMembership from 'src/api/useMembership';
import usePreviewCancelMembership from 'src/api/usePreviewCancelMembership';
import { usePreventAgentAccess } from 'src/hooks/usePreventAgentAccess';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import StateFarmLogo from 'src/components/agents/StateFarmLogo';
import { SimpleFooter } from 'src/features/footer';

type CancelDetails = {
  refundType: string;
  leadText: string;
  amount: number;
  refundOutcome: string;
};

const CancelMembership: React.FC<PageProps> = ({ location }) => {
  usePreventAgentAccess();
  const { isShelbyCustomer } = useShelbyCustomer();
  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { data: membership, ...membershipQuery } = useMembership();
  const previewCancelMembership = usePreviewCancelMembership(membership?.data || {});
  const cancelMembership = useCancelMembership(membership?.data || {}, null);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [cancelDetails, setCancelDetails] = useState<CancelDetails>({} as CancelDetails);
  const format = useDateFormat();
  useTracking('cancel_plan');
  const { trackInteraction } = useTrackingContext();

  const i18n = {
    confirmButton: 'updateMembership.confirmButton.text',
    cancelButton: 'cancelPlan.cancelButton.text',
    expiresNow: 'cancelPlan.leadText.expiresNow',
    expiresLater: 'cancelPlan.leadText.expiresLater',
    refundProrated: 'cancelPlan.refundDue.prorated.msg',
    refundNone: 'cancelPlan.refundDue.none.msg',
    heading: 'cancelPlan.heading',
    refundTitle: 'cancelPlan.refund.title',
    continue: 'cancelPlan.continue',
    bannerCancelNow: 'cancelPlan.banner.cancel.now',
    bannerCancelLater: 'cancelPlan.banner.cancel.later',
  };

  const { state = {} } = location;
  const shouldRedirect = !state?.['nav'];

  useEffect(() => {
    if (isAuthenticated && membershipQuery?.refetch) membershipQuery.refetch();
  }, [isAuthenticated, membershipQuery]);

  useEffect(() => {
    const unauthenticated = !loadingUser && !isAuthenticated;
    const hasMembership = membership?.hasMembership();

    if (unauthenticated || hasMembership === false) {
      // use === false here will exclude the undefined case
      if (membershipQuery?.stopPolling) membershipQuery?.stopPolling();
      if (!submittedForm) navigate('/checkout/', { replace: true });
      // Redirect member users that navigated directly
    } else if (shouldRedirect && hasMembership) {
      membershipQuery?.stopPolling();
      navigate('/my-account/change-plan/');
    }
  }, [loadingUser, isAuthenticated, membership, membershipQuery]);

  useEffect(() => {
    if (membership?.data?.id && !submittedForm) {
      previewCancelMembership().then(({ data }) => {
        const details = data.previewCancelMembership.data;
        details['leadText'] = details.refundType === 'none' ? i18n.expiresLater : i18n.expiresNow;

        // Default: full refund, shows no extra text
        details['refundOutcome'] = '';
        if (details.refundType === 'partial') details['refundOutcome'] = i18n.refundProrated;
        else if (details.refundType === 'none') details['refundOutcome'] = i18n.refundNone;
        setCancelDetails(details);
      });
    }
    if (submittedForm && (membership?.hasMembership() === false || membership.willBeCanceled())) {
      membershipQuery?.stopPolling();
      membershipQuery?.refetch(); // Membership is not being updated on the cached data
      navigate('/my-account/', {
        state: {
          msg: membership?.willBeCanceled() ? i18n.bannerCancelLater : i18n.bannerCancelNow,
          date: format(membership?.willBeCanceled() ? membership.expireAt : new Date()),
        },
      });
    }
  }, [membership?.data]);

  const handleOnClick = () => {
    trackInteraction(
      undefined,
      undefined,
      { hdc_refund: (cancelDetails.amount / 100).toFixed(2) },
      'cancel_plan_confirm'
    );
    setSubmittedForm(true);
    cancelMembership().then((r) => {
      membershipQuery?.startPolling(200);
    }, console.error);
  };

  return (
    <>
      {isAuthenticated && membership?.hasMembership() && cancelDetails?.refundType && (
        <div className="container container_center page__update_membership">
          <Translate as="h2" className="text-display_2" resourceKey={i18n.heading} />
          <Translate as="p" resourceKey={cancelDetails.leadText} data-cy="membership-ends" />

          <div className="inset-l" />
          {cancelDetails.amount > 0 && (
            <p>
              <Translate resourceKey={i18n.refundTitle} />
              <span className="refund-due-amount">
                ${typeof cancelDetails.amount === 'number' && (cancelDetails.amount / 100).toFixed(2)}
              </span>
            </p>
          )}
          {cancelDetails.refundOutcome && (
            <Translate as="p" resourceKey={cancelDetails.refundOutcome} data-cy="refund-outcome" />
          )}

          <div className="inset-m" />
          <Translate as="p" resourceKey={i18n.continue} />
          <div className="inset-m" />
          <div className="cta_button-group button-group">
            <div className="button-group__item">
              <button
                onClick={handleOnClick}
                disabled={submittedForm}
                data-cy="confirm-cancel-membership"
                className="button button_primary button_primary--large"
              >
                <Translate resourceKey={i18n.confirmButton} />
              </button>
            </div>
            <div className="button-group__item">
              <Link to={'/my-account/'} className="button button_secondary">
                <Translate resourceKey={i18n.cancelButton} />
              </Link>
            </div>
          </div>

          <div className="inset-l" />

          {isShelbyCustomer && <StateFarmLogo />}

          <div className="inset-l" />
        </div>
      )}

      <SimpleFooter />
    </>
  );
};

export default CancelMembership;
